'use client';
import { makeStyles } from 'lib/makeStyles';
import React, { useEffect, useState } from 'react';

import { NumberInputBox } from './NumberInputBox';

export type NumberInputOwnProps = {
  step?: number;
  initialValue?: number;
  fullWidth?: boolean;
  label?: string;
  isSmall?: boolean;
  // do not conflict with native onChange event
  onValueChange?: (value: number) => void;
} & Pick<JSX.IntrinsicElements['input'], 'min' | 'max'>;

export type NumberInputProps = NumberInputOwnProps & JSX.IntrinsicElements['input'];

export const useNumberInputStyles = makeStyles<Pick<NumberInputProps, 'fullWidth'>>()({
  label: ({ fullWidth }: NumberInputOwnProps) => [
    {
      'w-full': fullWidth
    }
  ],
  inputHidden: ['sr-only']
});

const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      children,
      id,
      name,
      min = 0,
      max = 9999,
      step = 1,
      label,
      initialValue,
      disabled,
      isSmall,
      fullWidth,
      onValueChange,
      ...inputProps
    }: NumberInputProps,
    ref
  ) => {
    const [value, setValue] = useState(initialValue ?? 0);
    const styles = useNumberInputStyles({ fullWidth });

    const onChangeProxy = (e) => {
      setValue(e.target.value);
    };

    useEffect(() => {
      onValueChange && onValueChange(value);
    }, [value]);

    return (
      <label htmlFor={id} className={styles.label}>
        <>
          <input
            type="number"
            id={id}
            name={name}
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChangeProxy}
            disabled={disabled}
            className={styles.inputHidden}
            ref={ref}
            {...inputProps}
          />
          <NumberInputBox
            value={value}
            onChange={setValue}
            label={label}
            min={min}
            max={max}
            step={step}
            isSmall={isSmall}
            fullWidth={fullWidth}
          />
        </>
        <div>{children}</div>
      </label>
    );
  }
);

NumberInput.displayName = 'NumberInput';

export { NumberInput };
