import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import AuthCode from 'react-auth-code-input';

// AuthCode props are not yet exposed https://github.com/drac94/react-auth-code-input/pull/47
export type OTPInputProps = {
  allowedCharacters?: 'alpha' | 'numeric' | 'alphanumeric';
  ariaLabel?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  length?: number;
  containerClassName?: string;
  inputClassName?: string;
  isPassword?: boolean;
  onChange: (res: string) => void;
};

export const useOTPInputStyles = makeStyles<
  Pick<OTPInputProps, 'inputClassName' | 'containerClassName'>
>()({
  inputClassName: (props) => [
    'rounded-md',
    'text-center',
    'shadow-sm hover:shadow',
    'border border-primaryBorder hover:border-primaryBorderHover',
    'caret-gray-100',
    'placeholder:paragraph-600 placeholder:text-sm',
    'text-secondaryText',
    props.inputClassName
  ],
  containerClassName: (props) => [
    'w-full',
    'flex justify-between space-x-4',
    props.containerClassName
  ]
});

const OTPInput: React.ComponentType<OTPInputProps> = React.memo(
  ({
    inputClassName,
    containerClassName,
    length = 5,
    allowedCharacters = 'numeric',
    autoFocus = true,
    ...ownProps
  }) => {
    const styles = useOTPInputStyles({ inputClassName, containerClassName });

    return (
      <AuthCode
        length={length}
        allowedCharacters={allowedCharacters}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        {...ownProps}
        inputClassName={styles.inputClassName}
        containerClassName={styles.containerClassName}
      />
    );
  }
);

OTPInput.displayName = 'OTPInput';

export { OTPInput };
